/*!
 * Start Bootstrap - Sanjeet - Portfolio v4.0.1 ()
 * Copyright 2013-2017 Sanjeet Kumar
 * Licensed under MIT (https://github.com/BlackrockDigital/portfolio/blob/master/LICENSE)
 */

//  colors
$body_bg_color: #868e96;
$primary_color: #BD5D38;
$primary_color_focus: #824027;
$heading_text_color: #343a40;

 body {
  font-family: 'Open Sans', serif;
  padding-top: 54px;
  color: $body_bg_color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', serif;
  font-weight: 700;
  text-transform: uppercase;
  color: $heading_text_color;
}

h1 {
  font-size: 5rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Saira Extra Condensed', serif;
  font-size: 1.35rem;
}

.list-social-icons a {
  color: #495057;
}
.list-social-icons a:hover {
  color: $primary_color;
}
.list-social-icons a .fa-lg {
  font-size: 1.75rem;
}

.list-icons {
  font-size: 3rem;
}
.list-icons .list-inline-item i:hover {
  color: $primary_color;
}

.remove-underline {
  text-decoration: none !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

.normal-text {
  text-transform: none !important;
}

#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 600;
  text-transform: uppercase;
}

section.resume-section {
  border-bottom: 1px solid #dee2e6;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

section.resume-section .resume-item .resume-date {
  min-width: none;
}

.bg-primary {
  background-color: $primary_color !important;
}

.text-primary {
  color: $primary_color !important;
}

a {
  color: $primary_color;
}
a:hover, a:focus, a:active {
  color: $primary_color_focus;
}

.exp_date {
  font-size: 0.9rem;
}
.skill_icon {
  vertical-align: middle;
}
.skill_name {
  vertical-align: middle;
  font-size: 16px;
  color: #868e96;
}

// tags
.tags {
  font: 12px/1.5 'PT Sans', serif;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: $primary_color;
  color: white;
  cursor: pointer;
}

.tag:hover::after {
  border-left-color: $primary_color;
}

.skill_title {
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 4px solid $primary_color;
  position: relative;
  line-height: 38px;
}

.skill_title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 0;
  height: 0;
  border-left: 8px solid $primary_color;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  clear: both;
}

.qr_image {
  text-align: center;
  img {
    width: 120px;
  }
}

.mobile_number { 
  font-weight: 500;
  font-family: 'Saira Extra Condensed', serif;
  font-size: 1.35rem;
  letter-spacing: 1.5px;
  color: #868e96;
  text-decoration: none !important;
}
.mobile_number:hover, .mobile_number:focus, .mobile_number:active {
  color: #868e96;
}

// media query for tablets
@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem;
  }
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh;
  }
  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }
  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }
  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }
  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}

@media (max-width: 992px) {
  .img-profile {
    width: auto;
    height: 1.6rem;
    border: 0.1rem solid rgba(255, 255, 255, 0.2);
    margin-bottom: 0px !important;
    margin-right: .5rem !important;
  }
}


// media query for mobile
@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh;
  }
  section.resume-section .resume-item .resume-date {
    min-width: 18rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  .qr_image {
    text-align: left;
    img {
      margin-bottom: 40px;
      margin-top: -30px;
    }
  }
}

@media (min-width: 1367px) {
  body {
    padding-left: 0px;
    background-color: #f5f5f5;
  }
  //css grid layout
  .home_container {
    display: grid;
    grid-template-columns: 17rem 1fr;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    max-width: 1367px;
  }

  .home_container .home_container_item_1 #sideNav {
    position: sticky;
  }
}




